import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import Background from "bg/3.png";
import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${Background});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;
const SubText = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 font-medium`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#home">
        Home
      </NavLink>
      <NavLink href="#impact">
        Impact
      </NavLink>
      <NavLink href="#footprints">
        Footprints
      </NavLink>
      {/* <NavLink href="#testimonials">
        Testimonials
      </NavLink> */}
      <NavLink href="#faq">
        FAQ
      </NavLink>
      <NavLink href="#contactus">
        Contact Us
      </NavLink>
    </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/#">
    //     Create an Account
    //   </PrimaryLink>
    // </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Notification>Dec, 2021 - We have now launched operations in Brazil.</Notification>
            <Heading>
              <span>ESG  automation to</span>
              <br />
              <SlantedBackground>scale your business</SlantedBackground>
            </Heading>
            <br />
            <SubText>Say good bye to manual work! We collect the environmental, 
            <br />social, governance and  financial information of your clients 
            <br />to save your time and costs when you provide your services. </SubText>
            <br />
            <PrimaryAction>Contact us now!</PrimaryAction>
            <br />
            <br />
            <br />
            <br />
            <br />
          </LeftColumn>
          <RightColumn>

          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
